import React, { useState } from 'react';
import nelson from '../img/4.jpeg'; // Reemplaza con la ruta correcta del ícono de Twitter
import william from '../img/1.jpeg'; // Reemplaza con la ruta correcta del ícono de Twitter

import paulina from '../img/5.jpeg'; // Reemplaza con la ruta correcta del ícono de Twitter

import sonia from '../img/2.jpeg'; // Reemplaza con la ruta correcta del ícono de Twitter

import jenny from '../img/3.jpg'; // Reemplaza con la ruta correcta del ícono de Twitter




import '../App.css';

function QuienesSomos() {
  const [selectedCard, setSelectedCard] = useState(null);

  

  const directiva = [
    {
      nombre: 'Nelson Bobadilla Rodríguez',
      cargo: 'Director',
      correo: 'Correo: nbobadilla@nzelandia.cl', // Texto del correo electrónico
      imagen: nelson,

    },
    {
      nombre: 'William José Carrero Contreras',
      cargo: 'Inspector General',
      correo: 'Correo: wcarrero@nzelandia.cl', // Texto del correo electrónico
      imagen: william,

    },
    {
      nombre: 'Paulina Andrea Canelo Flores',
      cargo: 'UTP',
      correo: 'Correo: pcanelo@nzelandia.cl', // Texto del correo electrónico
      imagen: paulina,

    },
    {
      nombre: 'Sonia Véronica Espejo Silva',
      cargo: 'Convivencia Escolar',
      correo: 'Correo: sespejo@nzelandia.cl', // Texto del correo electrónico
      imagen: sonia,

    },
    {
      nombre: 'Jenny Gaete Salgado',
      cargo: 'Coordinadora P.I.E.',
      correo: 'Correo: jgaete@nzelandia.cl', // Texto del correo electrónico
      imagen: jenny,
      

    },
    // Agrega más profesores aquí
  ];

  const handleCardClick = (correo) => {
    setSelectedCard(correo);
  };

  const closeCard = () => {
    setSelectedCard(null);
  };

  return (
    <section id="quienes-somos">
      <div className="content">
        <h2>Quiénes Somos</h2>
        <p>
        Somos una escuela pública, de educación Parvularia y Básica, que fue fundada el año 1966 en el marco de la reforma educativa de 1965. En la actualidad depende la administración comunal de la Municipalidad de Independencia la cual, según la ley 18.695, tiene por función satisfacer las necesidades de la comunidad local y asegurar su participación en el progreso económico, social y cultural de las respectivas comunas. Nos encontramos ubicados en el sector norte de la Región Metropolitana en la ciudad de Santiago, comuna de Independencia y específicamente en la población Juan Antonio Ríos. Esta terminó de ser edificada hacia la década del 70, por lo cual diversas generaciones del barrio y sus alrededores han estudiado en la escuela que hoy conocemos con el nombre de Nueva Zelandia.        </p>
        <div className="image-container">
          <section>
            <div className="content">
              <h2>Equipo de Gestion</h2>
              <p>¡Conoce a nuestro Equipo directivo !</p>
              
              {/* <img src={fotoquienesomos} /> */}
              <div className="alumnos-container">
              {directiva.map((directivo, index) => (
                <div
                  key={index}
                  className={`alumno-card ${selectedCard === directivo.correo ? 'active' : ''}`}
                  onClick={() => handleCardClick(directivo.correo)}
                >
                  <img src={directivo.imagen} alt={`${directivo.nombre} - ${directivo.cargo}`} />
                  <h3>{directivo.nombre}</h3>
                  <p>{directivo.cargo}</p>
                  <span style={{ userSelect: "none" }}>{directivo.correo}</span>
                  
                </div>
              ))}

              </div>
            </div>
           
          </section>
        </div>
      </div>
      <style jsx>{`
        /* Estilos anteriores... */

        .alumnos-container {
          display: flex;
          justify-content: center; /* Alinear las tarjetas al centro horizontalmente */
          flex-wrap: wrap; /* Esto permite que las tarjetas se envuelvan si no caben en la pantalla */
          gap: 20px;
        }

        .alumno-card {
          border: 1px solid #ccc;
          border-radius: 10px;
          padding: 20px;
          text-align: center;
          background-color: #f7f7f7;
          transition: transform 0.2s, box-shadow 0.2s;
          width: 220px; /* Ancho de cada tarjeta */
          cursor: pointer;
        }

        .alumno-card:hover {
          transform: translateY(-15px);
          box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
        }

        /* Estilos para la tarjeta activa (con correo visible) */
        .alumno-card.active {
          transform: translateY(0);
          box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
        }
        .alumno-card img {
          width: 80px;
          height: 100px;
          border-radius: 50%;
          transition: transform 0.2s; /* Agregar transición al efecto de lupa */
          object-fit: cover; 
        }
      
        .alumno-card:hover img {
          transform: scale(1.1); /* Escalar la imagen al hacer hover para crear el efecto de lupa */
        }
      

        .correo {
          margin-top: 10px;
          font-weight: bold;
          user-select: none; /* Evitar la selección de texto */
          cursor: default; /* Cambiar el cursor a predeterminado para indicar que no es seleccionable */
          color: #888; /* Color de texto más claro para indicar que está bloqueado */
        }
        h5{
          font-size: 15px;
        } 

        /* Resto de los estilos... */
        
      `}</style>
    </section>
  );
}

export default QuienesSomos;
