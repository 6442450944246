import React, { useState, useEffect } from 'react';
import '../App.css';

function FloatingButton() {
  const [hovered, setHovered] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const scrollTo = () => {
    if (isAtTop) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = () => {
    const isTop = window.scrollY === 0;
    setIsAtTop(isTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      className={`btn-flotante ${hovered ? 'hovered' : ''}`}
      onClick={scrollTo}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-arrow-up-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        {!isAtTop ? (
          <>
            <polyline points="16 12 12 8 8 12"></polyline>
            <line x1="12" y1="16" x2="12" y2="8"></line>
          </>
        ) : (
          <>
            <polyline points="8 12 12 16 16 12"></polyline>
            <line x1="12" y1="8" x2="12" y2="16"></line>
          </>
        )}
      </svg>
    </button>
  );
}

export default FloatingButton;
