import React from 'react';
import '../App.css';


 function Inicio() {
  return (
     <section id="inicio">
        <div className="hero">
           <h1 className='texto-portada'></h1> 
        </div>
      </section> 
  );
}

export default Inicio;
