import React from 'react';
import '../App.css'; // Asegúrate de tener el archivo de estilos

class Proyectos extends React.Component {
  render() {
    return (
      <div className="proyecto-container" id='proyecto-container'>
        <h3 className="galeria-title">Nuestros Proyectos</h3>
        
        <div className="proyectos-list">
          <div className="proyecto-card">
            <h3>Aulas Conectadas</h3>
            <p>Descripción del proyecto Aulas Conectadas en construcción...</p>
          </div>
          <div className="proyecto-card">
            <h3>Matemática en Ruta</h3>
            <p>Descripción del proyecto Matemática en Ruta en construcción...</p>
          </div>
          <div className="proyecto-card">
            <h3>Certificación Medioambiental</h3>
            <p>Descripción del proyecto Certificación Medioambiental en construcción...</p>
          </div>
          <div className="proyecto-card">
            <h3>Programa Aprender en Familia</h3>
            <p>Descripción del proyecto Programa Aprender en Familia  en construcción...</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Proyectos;
